<template>
<div class="device-bind-detail" id="page">
  <el-row class="dbd-form-line row-start-between">
    <el-form :model="form" class="row-center-start" ref="formRef">
      <el-row>
        <el-form-item label="设备类型">
          <el-select v-model="form.t_device_type_id" size="small">
            <el-option v-for="(item, index) in form.deviceTypeOption" :key="index" :label="item.txt_type_name" :value="item.t_device_type_id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <date-picker ref="datePickerRef" @dateChange="(e) => {changeDate(e)}" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getTableData()">加载数据</el-button>
          <el-button type="primary" :disabled="(table.data||[]).length==0" @click="exportTo">导出到Excel</el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" :summary-method="getSummaries" show-summary sum-text="合计">
    <el-table-column prop="txt_device_iot" label="设备唯一识别码" align="center" />
    <el-table-column prop="dt_clinic_agent" label="绑定时间" align="center" />
    <el-table-column prop="txt_type_name" label="设备类型" align="center" />
    <el-table-column prop="txt_province_name" label="省份" align="center" />
    <el-table-column prop="txt_province_partner_name" label="省代" align="center" />
    <el-table-column prop="txt_city_name" label="城市" align="center" />
    <el-table-column prop="txt_city_partner_name" label="市代" align="center" />
    <el-table-column prop="txt_salesman_name" label="业务员" align="center" />
    <el-table-column prop="txt_clinic_agent_name" label="诊所医生" align="center" />
    <el-table-column prop="txt_clinic_name" label="诊所" align="center" />
  </el-table>
  <pagination :total="page.total" @sizeChange="(e) => {page.limit=e;getTableData()}" @pageChange="(e) => {page.page=e;getTableData()}" />
</div>
</template>

<script>
import { ElMessage } from 'element-plus';
import { Pagination } from "components";
import { deviceBindDetailExport } from "assets/js/export";
import { onMounted, reactive, toRefs, ref } from 'vue';
import { getDeviceType, getDeviceBindingDetails } from "api/apis.js";
import datePicker from "views/repertory/comps/datePicker.vue";
// 统计分析 - 设备绑定明细
export default {
  components: {
    Pagination,
    datePicker
  },
  setup() {
    const datePickerRef = ref(null);
    const state = reactive({
      form: {
        device_type_id: "",
        deviceTypeOption: [],
        dateType: "",
        dateRange: []
      },
      page: { total: 0, limit: 10, page: 1 },
      table: {
        data: [],
        loading: false
      }
    })

    onMounted(() => {
      getDevices();
      state.form.dateRange = datePickerRef.value.dateRange;
      state.form.dateType = "w";
    })

    // 导出
    const exportTo = () => {
      getDeviceBindingDetails({
        device_type_id: state.form.t_device_type_id,
        limit: 9999,
        page: 1,
        days: state.form.dateType == 'w' ? 7 : 30,
        dt_from: state.form.dateRange[0],
        dt_to: state.form.dateRange[1]
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            deviceBindDetailExport(response.data.data, response.data.count);
          }
        } else {
          ElMessage.error(response.msg);
        }
      })
    };

    // 自定义 【合计】
    const getSummaries = (param) => {
      const { columns, data } = param;
      console.log(data)
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总计';
          return;
        } else if (index == 1) {
          sums[index] = state.page.total;
        } else {
          sums[index] = "";
        }
      });
      return sums;
    };

    const changeDate = (e) => {
      state.form.dateType = e.substring(1, 0);
      state.form.dateRange = datePickerRef.value.dateRange;
      getTableData();
    }

    // 获取数据
    const getTableData = () => {
      state.table.loading = true;
      getDeviceBindingDetails({
        device_type_id: state.form.t_device_type_id,
        limit: state.page.limit,
        page: state.page.page,
        days: state.form.dateType == 'w' ? 7 : 30,
        dt_from: state.form.dateRange[0],
        dt_to: state.form.dateRange[1]
      }).then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.table.data = response.data.data;
            state.page.total = response.data.count;
          } else {
            state.table.data = [];
            state.page.total = 0;
          }
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    // 获取设备类型
    const getDevices = () => {
      getDeviceType().then(response => {
        if (response.code == 200) {
          if (response.data) {
            state.form.deviceTypeOption = response.data;
            state.form.deviceTypeOption.unshift({ t_device_type_id: 0, txt_type_name: "全部" })
            state.form.t_device_type_id = state.form.deviceTypeOption[0].t_device_type_id;
            getTableData();
          } else {
            state.form.deviceTypeOption = [];
            state.table.data = [];
          }
        } else {
          state.form.deviceTypeOption = [];
        }
      }).catch(e => {
        console.error(e);
        ElMessage.error(e);
      })
    };

    return {
      ...toRefs(state),
      getDevices,
      getTableData,
      getSummaries,
      exportTo,
      changeDate,
      datePickerRef
    }
  }
}
</script>

<style lang="scss">
.device-bind-detail {
  .el-table {
    height: calc(100% - 95px);

    .el-table__inner-wrapper {
      height: calc(100% - 40px);
    }
  }
}
</style>
